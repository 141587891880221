import React from 'react';

import Layout from '../../components/Layout';
import TriggerBuildButton from '../../components/TriggerBuildButton';

const AIRTABLE_LINK = 'https://airtable.com/tbloqiBhFFsXjVm1d/viwPqNgTF03BK9iok?blocks=hide';

const RebuildSite = () => (
  <Layout>
    To rebuild the site using the latest
    { ' ' }
    <a href={ AIRTABLE_LINK }>Airtable</a>
    { ' ' }
    data, please click the button below and wait up to five minutes for the changes to be visible.
    <TriggerBuildButton />
  </Layout>
);

export default RebuildSite;
