import React, { useState } from 'react';

const REBUILD_URL = 'https://api.netlify.com/build_hooks/5fb25def1f2ae5a3a5b9dc9d';

const TriggerBuildButton = () => {
  const [ clicked, setClicked ] = useState(false);

  const onClick = async e => {
    e.preventDefault();
    await window.fetch(REBUILD_URL, {
      method: 'POST',
    });
    setClicked(true);
  };

  return (
    <div>
      <img src="https://api.netlify.com/api/v1/badges/ec6a821e-5f4a-4d87-8ab2-124cc6314f0d/deploy-status" alt="Deploy status badge" />
      <br />
      <button type="button" onClick={ onClick }>
        Rebuild site
        { clicked && (<span> (Clicked... waiting)</span>) }
      </button>
    </div>
  );
};

export default TriggerBuildButton;
